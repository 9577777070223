<template>
  <i-container
    v-if="response"
    class="
      _margin-top-xs-0
      _margin-top-sm-0
      _margin-top-md-6
      _margin-top-lg-6
      _margin-top-xl-6
      _margin-bottom-6
    "
  >
    <i-row>
      <i-column>
        <i-container>
          <prismic-rich-text :field="response.data.cv" class="bock--small" />
        </i-container>
      </i-column>
    </i-row>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
  </i-container>
</template>

<script>
export default {
  name: "Resume",
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle("resume");
      this.response = response;
    },
  },
  created() {
    this.getContent();
  },
};
</script>
